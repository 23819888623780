// Animated Section
var animatedSections = jQuery('.animated-section');
var windowHeight = window.innerHeight;

if(animatedSections.length > 0) {
  animatedSections.each(function () {
    var section = jQuery(this);
    var offsetTop = section.offset().top;

    if(window.pageYOffset >= offsetTop - windowHeight / 1.5) {
      jQuery(section).addClass('active');
    }
  });

  window.addEventListener('scroll', function () {
    animatedSections.each(function () {
      var section = jQuery(this);
      var offsetTop = section.offset().top;

      console.log(offsetTop)
      console.log(window.pageYOffset)

      if(window.pageYOffset >= offsetTop - windowHeight / 1.5) {
        jQuery(section).addClass('active');
      }
    });
  });
}